<template>
  <div class="content" v-title data-title="临床研究">
    <div class="h80"></div>
    <!-- <div
        style="
          padding: 0px 20px;
          position: fixed;
          background: #fff;
          z-index: 99;
          width: 100%;
          background: #fff;
        "
      >
        <el-button
          style="font-size: 17px"
          type="text"
          icon="el-icon-arrow-left"
          @click="$router.go(-1)"
          >返回</el-button
        >
      </div>
      <div style="height: 40px"></div> -->
    <div class="top">
      <div>
        <i
          v-show="data.level == '99'"
          class="el-icon-star-on"
          style="color: red"
        ></i>
        {{ data.name }}
        <i
          v-if="data.level != '96'"
          class="el-icon-s-flag"
          :class="
            data.level == '99' || data.level == '98' ? 'stress' : 'stressless'
          "
        ></i>
      </div>
      <div style="font-size: 14px; color: #3e3e3e; margin-top: 10px">
        编号：{{ data.projectCode }}
      </div>
      <div style="font-size: 14px; color: #3e3e3e; margin-top: 10px">
        医院：{{ data.hospitalName }}
      </div>
    </div>

    <div class="nav">
      <div class="nav_list" @click="active = false">简明条件</div>
      <div class="nav_list" @click="active = true">了解详情</div>
      <div class="move" :class="active ? 'moveLeft' : 'moveRight'"></div>
    </div>
    <div class="box1" v-show="!active">
      <div class="main">
        <div
          v-for="(item, index) in list"
          style="padding-bottom: 1.5rem"
          :key="index"
        >
          <div class="main_list_title">{{ item.typeName }}</div>
          <div v-html="item.content"></div>
        </div>
      </div>
    </div>
    <div class="box2" v-show="active">
      <div class="box2_look" v-if="data.detailAttach">
        点击查看详细条件附件:
        <a
          :href="data.detailAttach"
          download="详细条件.pdf"
          style="color: #5696f7"
          >详细条件</a
        >
      </div>
      <div class="box2_look" v-if="data.consentAttach">
        点击查看知情同意书附件：<a
          :href="data.consentAttach"
          download="知请同意书.pdf"
          style="color: #5696f7"
          >知请同意书</a
        >
      </div>
      <div class="footer">
        <img src="https://b.yi-lian.net/yskf.png" />
        <div>扫码添加客服了解更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import { detail } from "@/api/index";
import { wxChatShare } from "../../utils/wechat";
export default {
  data() {
    return {
      data: {},
      list: [],
      active: false,
    };
  },
  watch: {},
  created() {
    this.createE().then(() => {
      let params = {
        url: window.location.href,
        title: "临床研究",
        link: window.location.href.replace(window.location.search, ""),
        imgUrl:
          "https://yilian-biz.oss-cn-shanghai.aliyuncs.com//upload/20211112/03eab30b96b9997a72503b690552fb6e.jpeg",
        desc: this.data.name,
        type: "",
        dataUrl:
          "",
      };
      wxChatShare(params);
    });
  },
  mounted() {},
  methods: {
    async createE() {
      let data = await detail(this.$route.query.id);
      this.data = data.data;
      this.list = JSON.parse(this.data.appendVOList);
      this.list.forEach((item) => {
        item.content = this.htmlDecode(item.content);
      });
    },
    htmlDecode(text) {
      var temp = document.createElement("div");
      temp.innerHTML = text;
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  height: 100%;
  // background: #f9f9f9;
}
.top {
  padding: 8px 10px;
  font-size: 18px;
}
.nav {
  margin: 10px;
  height: 45px;
  background: #efefef;
  border-radius: 20px;
  position: relative;
}
.main {
  padding: 8px 10px;
  min-height: 80vh;
}

.stress {
  margin-left: 10px;
  color: red;
}
.stressless {
  margin-left: 10px;

  color: #38d73b;
}
.main_list_title {
  font-size: 1.4rem;
  border-bottom: 1px solid #558bc4;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.footer {
  text-align: center;
  padding: 0 35%;
  padding-bottom: 2rem;
}
.footer > img {
  width: 100%;
}

.nav_list {
  display: inline-block;
  width: 50%;
  height: 100%;
  position: relative;
  z-index: 2;
  color: #38d73b;
  line-height: 40px;
  text-align: center;
  color: #000;
  font-size: 14px;
}
.move {
  box-sizing: border-box;
  margin: 4px;
  background: #ffffff;
  width: 50%-2px;
  height: 35px;
  border-radius: 30px;
  position: absolute;
  top: 0;
}
.moveLeft {
  animation: mymoveleft 0.5s forwards;
}

.moveRight {
  animation: moveRight 0.5s forwards;
}

@keyframes mymoveleft {
  from {
    left: 0;
  }
  to {
    left: 50%;
  }
}

@keyframes moveRight {
  from {
    left: 50%;
  }
  to {
    left: 0%;
  }
}

.box2 {
  padding: 10px;
  text-align: center;
}
.box2_look {
  font-size: 14px;
  margin-bottom: 15px;
}
</style>
